import React, { Dispatch, SetStateAction } from "react";
import { useAuth0, LogoutOptions } from "@auth0/auth0-react";
import { User } from "@auth0/auth0-spa-js";
import { styled, alpha } from "@mui/system";
import {
  Typography,
  Box,
  Menu as OriginalMenu,
  MenuItem as OriginalMenuItem,
  Divider,
} from "@mui/material";
import { LogoutRounded as LogoutIcon } from "@mui/icons-material";
import { useSubdomain } from "../../contexts/SubdomainContext";

interface AccountMenuProps {
  user: User | undefined;
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

const AccountMenu = ({ user, anchorEl, setAnchorEl }: AccountMenuProps) => {
  const { logout } = useAuth0();
  const { subdomain } = useSubdomain();
  const redirectUri = new URL(process.env.REACT_APP_AUTH0_REDIRECT_URI || "");
  redirectUri.hostname = `${subdomain}.${redirectUri.hostname}`;

  const handleLogout = () => {
    logout({ returnTo: redirectUri.toString() } as LogoutOptions);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      keepMounted
    >
      <UserInfoMenuItem>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" fontWeight={500}>
            {user?.name}
          </Typography>
          <Typography variant="body2">{user?.email}</Typography>
        </Box>
      </UserInfoMenuItem>

      <Divider />

      <LogoutMenuItem onClick={handleLogout}>
        <Box display="flex" gap={1}>
          <LogoutIcon fontSize="small" />
          <Typography variant="body2">Log out</Typography>
        </Box>
      </LogoutMenuItem>
    </Menu>
  );
};

export default AccountMenu;

const Menu = styled(OriginalMenu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.spacing(1.25),
    boxShadow: `0px 0px 5px lightgrey`,
  },
  "& .MuiList-root": {
    padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)}`,
  },
}));

const MenuItem = styled(OriginalMenuItem)(({ theme }) => ({
  padding: `${theme.spacing(1.25)} 0`,
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
}));

const UserInfoMenuItem = styled(MenuItem)({
  cursor: "default",
});

const LogoutMenuItem = styled(MenuItem)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.6),
  "&:hover": {
    color: theme.palette.error.main,
  },
}));
