export const handleSquareError = (error, enqueueSnackbar) => {
  console.error(error);
  const errorResponse = error.response.data[0];
  const errorMessage = errorResponse.detail || errorResponse.code;
  enqueueSnackbar(errorMessage, { variant: "error" });
};

export const handleChatError = (error, enqueueSnackbar) => {
  console.error(error);
  const errorMessage =
    "There was a problem with your request, please try again.";
  enqueueSnackbar(errorMessage, { variant: "error" });
};
