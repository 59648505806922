import React, { ChangeEvent, useState } from "react";
import { styled } from "@mui/system";
import { Box, TextField as OriginalTextField } from "@mui/material";
import { Button, CircularProgress } from "../styled";
import { User, CustomerRequest } from "../../types";

interface ContactFormProps {
  user: User | undefined;
  handleBack: () => void;
  handleNext: () => void;
  handleCustomerCreation: (
    customerRequest: CustomerRequest,
    proceed: () => void,
    userData?: any
  ) => Promise<void>;
  loading: boolean;
}

const ContactForm = ({
  user,
  handleBack,
  handleNext,
  handleCustomerCreation,
  loading,
}: ContactFormProps) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(!validateEmail(event.target.value));
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    setPhoneError(!validatePhone(event.target.value));
  };

  const allFieldsFilled = firstName && lastName && (user || (email && phone));
  const anyError = emailError || phoneError;

  const handleNextClick = async () => {
    const customerRequest = {
      customer: {
        given_name: firstName,
        family_name: lastName,
        email_address: email,
        phone_number: phone,
      },
    };
    const userData = {
      first_name: firstName,
      last_name: lastName,
    };
    await handleCustomerCreation(customerRequest, handleNext, userData);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box padding={2.5}>
        <TextFieldRow>
          <TextField
            label="First name"
            name="firstName"
            value={firstName}
            onChange={handleFirstNameChange}
            required
            fullWidth
          />
          <TextField
            label="Last name"
            name="lastName"
            value={lastName}
            onChange={handleLastNameChange}
            required
            fullWidth
          />
        </TextFieldRow>
        {!user && (
          <TextFieldRow>
            <TextField
              error={emailError}
              helperText={emailError ? "Invalid email" : null}
              label="Email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              required
              fullWidth
            />
            <TextField
              error={phoneError}
              helperText={phoneError ? "Invalid phone number" : null}
              label="Phone"
              name="phone"
              value={phone}
              onChange={handlePhoneChange}
              required
              fullWidth
            />
          </TextFieldRow>
        )}
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Button onClick={handleBack}>Back</Button>
        <Button
          onClick={handleNextClick}
          variant="contained"
          disabled={!allFieldsFilled || anyError}
        >
          {loading ? <CircularProgress size={24} /> : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;

const TextFieldRow = styled(Box)(({ theme }) => ({
  minHeight: theme.spacing(9),
  display: "flex",
  justifyContent: "center",
  gap: "5%",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const TextField = styled(OriginalTextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.25),
}));
