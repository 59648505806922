import React, { useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/system";
import { theme, StyledMaterialDesignContent } from "./theme";
import { SnackbarProvider } from "notistack";
import { useSubdomain, SubdomainProvider } from "./contexts/SubdomainContext";
import Header from "./components/Header";
import ChatWindow from "./components/ChatWindow";

function App() {
  const { subdomain, allowedSubdomains } = useSubdomain();
  const redirectUri = new URL(process.env.REACT_APP_AUTH0_REDIRECT_URI || "");
  redirectUri.hostname = `${subdomain}.${redirectUri.hostname}`;

  useEffect(() => {
    if (
      subdomain &&
      allowedSubdomains &&
      !allowedSubdomains.includes(subdomain)
    ) {
      window.location.href = "https://baristagpt.com";
    }
  }, [subdomain, allowedSubdomains]);

  if (!subdomain || !allowedSubdomains?.includes(subdomain)) return null;

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: redirectUri.toString(),
      }}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
          }}
        >
          <div className="App">
            <Header />
            <ChatWindow />
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </Auth0Provider>
  );
}

function Main() {
  return (
    <SubdomainProvider>
      <App />
    </SubdomainProvider>
  );
}

export default Main;
