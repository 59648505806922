import React, { Dispatch, Fragment, SetStateAction } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import {
  Box,
  FormControl as OriginalFormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { Button, CircularProgress } from "../styled";
import { useSnackbar } from "notistack";
import { Location } from "../../types";

interface LocationFormProps {
  locations: Location[];
  selectedLocation: Location | undefined;
  setSelectedLocation: Dispatch<SetStateAction<Location | undefined>>;
  handleNext: () => void;
  loading: boolean;
}
const LocationForm = ({
  locations,
  selectedLocation,
  setSelectedLocation,
  handleNext,
  loading,
}: LocationFormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    const locationId = event.target.value;
    const location = locations.find((loc) => loc.id === locationId);
    setSelectedLocation(location);
  };

  const address = selectedLocation?.address;
  const opening_time = `${selectedLocation?.next_open_day} ${moment(
    selectedLocation?.next_open_time,
    "HH:mm:ss"
  ).format("h:mm A")}`;

  const getLocationHoursInfo = () => {
    if (selectedLocation?.is_open) {
      const closing_time = moment(
        selectedLocation.today_end_time,
        "HH:mm:ss"
      ).format("h:mm A");
      return (
        <Fragment>
          <Span color="green">Open</Span> ⋅ Closes {closing_time}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Span color="red">Closed</Span> ⋅ Opens {opening_time}
        </Fragment>
      );
    }
  };

  const handleNextButtonClick = () => {
    handleNext();
    if (!selectedLocation?.is_open) {
      enqueueSnackbar(
        `The location you selected is currently closed. Your order won't be prepared until we open ${opening_time}.`,
        { variant: "warning" }
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <LocationSelectBox>
        <FormControl>
          <InputLabel id="location-label">Location</InputLabel>
          <Select
            labelId="location-label"
            id="location"
            value={selectedLocation?.id}
            label="Location"
            onChange={handleLocationChange}
            sx={(theme) => ({
              borderRadius: theme.spacing(1.25),
            })}
          >
            {locations.map((loc) => (
              <MenuItem key={loc.id} value={loc.id}>
                {loc.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box>
          <Typography variant="h6">
            {selectedLocation?.business_name}
          </Typography>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            {selectedLocation?.name}
          </Typography>
          <Typography variant="body1">{address?.address_line_1}</Typography>
          <Typography variant="body1" gutterBottom>
            {address?.locality}, {address?.administrative_district_level_1}{" "}
            {address?.postal_code}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {getLocationHoursInfo()}
          </Typography>
        </Box>
      </LocationSelectBox>

      <Box textAlign="right">
        <Button onClick={handleNextButtonClick} variant="contained">
          {loading ? <CircularProgress size={24} /> : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default LocationForm;

const Span = styled("span")(({ color }) => ({
  color: color,
}));

const LocationSelectBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: "5%",
  textAlign: "left",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const FormControl = styled(OriginalFormControl)(({ theme }) => ({
  minWidth: "33%",
  marginBottom: theme.spacing(1.25),
}));
