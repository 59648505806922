import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { getSubdomains } from "../api";

const SubdomainContext = createContext<{
  subdomain: string | null;
  allowedSubdomains: string[] | null;
}>({ subdomain: null, allowedSubdomains: null });

export const useSubdomain = () => useContext(SubdomainContext);

interface SubdomainProviderProps {
  children: ReactNode;
}

export const SubdomainProvider = ({ children }: SubdomainProviderProps) => {
  const subdomain = window.location.hostname.split(".")[0];
  const [allowedSubdomains, setAllowedSubdomains] = useState<string[] | null>(
    null
  );

  useEffect(() => {
    getSubdomains().then((response) => {
      setAllowedSubdomains(response.data.subdomains);
    });
  }, []);

  return (
    <SubdomainContext.Provider value={{ subdomain, allowedSubdomains }}>
      {children}
    </SubdomainContext.Provider>
  );
};
