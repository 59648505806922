import axios from "axios";
import {
  ChatCompletionRequest,
  CustomerRequest,
  Message,
  Order,
} from "../types";

const API_URL = process.env.REACT_APP_API_URL;

export const postChatCompletion = async (
  request: ChatCompletionRequest,
  subdomain: string
) => {
  try {
    return await fetch(`${API_URL}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Client-Subdomain": subdomain,
      },
      body: JSON.stringify(request),
      credentials: "include",
    });
  } catch (error) {
    throw error;
  }
};

export const postPreviewOrder = async (
  request: ChatCompletionRequest,
  subdomain: string
) => {
  try {
    return await fetch(`${API_URL}/chat/preview_order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Client-Subdomain": subdomain,
      },
      body: JSON.stringify(request),
      credentials: "include",
    });
  } catch (error) {
    throw error;
  }
};

export const getRecommendedQuestions = async (lastMessage: Message) => {
  try {
    const message = lastMessage.choices[0].message;
    return await axios.post(`${API_URL}/chat/recommendations`, {
      messages: [message],
    });
  } catch (error) {
    throw error;
  }
};

export const getLocations = async (subdomain: string) => {
  try {
    return await axios.get(`${API_URL}/locations`, {
      headers: {
        "Client-Subdomain": subdomain,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const postCustomer = async (
  request: CustomerRequest,
  subdomain: string
) => {
  try {
    return await axios.post(`${API_URL}/customers`, request, {
      headers: {
        "Client-Subdomain": subdomain,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getUser = async (userId: string) => {
  try {
    return await axios.get(`${API_URL}/users/${userId}`);
  } catch (error) {
    throw error;
  }
};

export const patchUser = async (userId: string, userData: any) => {
  try {
    return await axios.patch(`${API_URL}/users/${userId}`, userData);
  } catch (error) {
    throw error;
  }
};

export const postUsualOrder = async (
  userId: string,
  client: string,
  order: Order
) => {
  try {
    return await axios.post(`${API_URL}/users/${userId}/usual_order`, order, {
      headers: {
        "Client-Subdomain": client,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const compareOrders = async (order: Order, usual_order: Order) => {
  try {
    return await axios.post(`${API_URL}/orders/compare`, {
      order,
      usual_order,
    });
  } catch (error) {
    throw error;
  }
};
export const getSubdomains = async () => {
  try {
    const environment = process.env.REACT_APP_DEPLOY_ENV;
    return await axios.get(`${API_URL}/integrations/subdomains`, {
      params: { environment },
    });
  } catch (error) {
    throw error;
  }
};
