import React, { ComponentProps } from "react";
import { styled, alpha } from "@mui/system";
import { Box, Typography as OriginalTypography, Divider } from "@mui/material";
import { Order } from "../../types";

interface OrderSummaryProps {
  order: Order;
}

const OrderSummary = ({ order }: OrderSummaryProps) => {
  const lineItems = order.line_items;
  const totalTaxAmount = order.total_tax_money?.amount;
  const totalAmount = order.total_money?.amount;

  return (
    <Box>
      <Box padding={1}>
        {lineItems?.map((item, index) => (
          <Box
            key={index}
            display="grid"
            gridTemplateColumns="10px minmax(0, 1fr) 50px"
            gap="2px 10px"
            paddingY={0.2}
          >
            <Box
              gridColumn="1 / 2"
              gridRow={`1 / span ${
                item.modifiers ? item.modifiers.length + 1 : 1
              }`}
            >
              <Typography variant="body2">{item.quantity}</Typography>
            </Box>
            <ItemNameBox gridColumn="2 / 3">
              <Typography variant="body2">
                {item.variation_name === "Regular"
                  ? item.name
                  : !item.variation_name.includes(item.name)
                  ? `${item.name}, ${item.variation_name}`
                  : item.name}
              </Typography>
            </ItemNameBox>
            <Box gridColumn="3 / 4" justifySelf="end">
              <Typography variant="body2">
                ${(item.variation_total_price_money.amount / 100).toFixed(2)}
              </Typography>
            </Box>

            {item.modifiers &&
              item.modifiers.map((modifier, modIndex) => (
                <React.Fragment key={modIndex}>
                  <Box gridColumn="2 / 3">
                    <Typography variant="body2" light>
                      {modifier.name}
                    </Typography>
                  </Box>
                  <Box gridColumn="3 / 4" justifySelf="end">
                    <Typography variant="body2" light>
                      ${(modifier.total_price_money.amount / 100).toFixed(2)}
                    </Typography>
                  </Box>
                </React.Fragment>
              ))}
          </Box>
        ))}
      </Box>
      <Divider />
      <Box padding={1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingY={0.2}
        >
          <Typography variant="body2">Subtotal</Typography>
          <Typography variant="body2">
            ${(((totalAmount || 0) - (totalTaxAmount || 0)) / 100).toFixed(2)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingY={0.2}
        >
          <Typography variant="body2">Sales Tax</Typography>
          <Typography variant="body2">
            ${((totalTaxAmount || 0) / 100).toFixed(2)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingY={0.2}
        >
          <Typography variant="body2" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            ${((totalAmount || 0) / 100).toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSummary;

interface TypographyProps extends ComponentProps<typeof OriginalTypography> {
  light?: boolean;
}

const Typography = styled(({ light, ...props }: TypographyProps) => (
  <OriginalTypography {...props} />
))(({ theme, light }) => ({
  fontSize: light ? theme.spacing(1.5) : undefined,
  color: light ? alpha(theme.palette.common.black, 0.6) : undefined,
}));

const ItemNameBox = styled(Box)({
  wordWrap: "break-word",
});
