import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { MaterialDesignContent } from "notistack";


export const theme = createTheme({
  palette: {
    primary: {
      main: "#096661",
    },
    secondary: {
      main: "#ba8d55",
      dark: "#af6423",
      light: "#d57b49",
    },
    light: {
      main: "#fdf8ec",
    },
  },
  typography: {
    fontFamily: "Quicksand",
    h1: {
      fontFamily: "Fonseca",
    },
    h2: {
      fontFamily: "Fonseca",
    },
    h3: {
      fontFamily: "Fonseca",
    },
    h4: {
      fontFamily: "Fonseca",
    },
    h5: {
      fontFamily: "Fonseca",
    },
    h6: {
      fontFamily: "Fonseca",
    },
    body1: {
      fontFamily: "Quicksand",
    },
    body2: {
      fontFamily: "Quicksand",
    },
    caption: {
      fontFamily: "Quicksand",
    },
  },
});

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    fontFamily: "Quicksand",
  },
  "&.notistack-MuiContent-error": {
    fontFamily: "Quicksand",
  },
  "&.notistack-MuiContent-warning": {
    fontFamily: "Quicksand",
    backgroundColor: theme.palette.warning.main,
  },
}));
