import React, { ChangeEvent, FormEvent, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/system";
import { Box, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { Button } from "../styled";
import { useSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";

interface MessageInputProps {
  input: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSendMessage: (message: string) => void;
  loading: boolean;
  displayMessageStream: boolean;
}

const MessageInput = ({
  input,
  handleChange,
  handleSendMessage,
  loading,
  displayMessageStream,
}: MessageInputProps) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (input.length <= 200) {
      handleSendMessage(input);
    } else {
      enqueueSnackbar("Your message is too long.", { variant: "error" });
    }
  };

  return (
    <Container>
      <Box position="relative" width={400}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <MessageTextField
            inputRef={inputRef}
            placeholder="Send a message"
            value={input}
            onChange={handleChange}
          />
          <SendButton
            type="submit"
            disabled={input === "" || loading || displayMessageStream}
          >
            {loading || displayMessageStream ? (
              <PulseLoader color={theme.palette.primary.main} size={5} />
            ) : (
              <SendIcon />
            )}
          </SendButton>
        </form>
      </Box>
    </Container>
  );
};

export default MessageInput;

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(0.5),
}));

const MessageTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1.25),
    boxShadow: `0px 0px 5px lightgrey`,
    "&.Mui-focused fieldset": {
      boxShadow: `0px 0px 5px lightgrey`,
    },
  },
  "& .MuiOutlinedInput-input": {
    paddingRight: theme.spacing(5),
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const SendButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  position: "absolute",
  right: theme.spacing(0.5),
  top: "50%",
  transform: "translateY(-50%)",
  minWidth: theme.spacing(5),
  height: theme.spacing(5),
}));
