import React, { useState, useEffect } from "react";
import { styled, alpha, useTheme } from "@mui/system";
import {
  Box,
  Typography,
  Switch as OriginalSwitch,
  Dialog as OriginalDialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  SwitchProps,
} from "@mui/material";
import { Button } from "../styled";
import { motion, AnimatePresence } from "framer-motion";
import FadeLoader from "react-spinners/FadeLoader";
import { useSnackbar } from "notistack";
import { useSubdomain } from "../../contexts/SubdomainContext";
import { compareOrders, postUsualOrder } from "../../api";
import { Order, User } from "../../types";

interface SaveOrderProps {
  order: Order;
  user: User;
}

const SaveOrder = ({ order, user }: SaveOrderProps) => {
  const theme = useTheme();
  const { subdomain } = useSubdomain();
  const { enqueueSnackbar } = useSnackbar();

  const usual_order = user.usual_order_by_client?.[subdomain || ""];

  const [isOrderSaved, setIsOrderSaved] = useState<boolean | null>(null);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (usual_order) {
      compareOrders(order, usual_order)
        .then((response) => setIsOrderSaved(response.data.are_equal))
        .catch((error) => console.error(error));
    }
  }, [order, usual_order, subdomain]);

  const handleSwitch = () => {
    if (isOrderSaved === false) {
      setOpenDialog(true);
    } else if (isOrderSaved === null) {
      handleSaveOrder();
    }
  };

  const handleSaveOrder = async () => {
    try {
      setLoading(true);
      await postUsualOrder(user._id, subdomain || "", order);
      setChecked(true);
    } catch (error) {
      enqueueSnackbar("There was a problem saving your order.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  return !isOrderSaved ? (
    <Container>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="body2" fontWeight={600} color="primary">
          {checked ? "Your usual is set!" : "Make this your usual?"}
        </Typography>
        {loading && (
          <FadeLoader
            color={theme.palette.primary.main}
            margin={-12}
            height={5}
            width={2}
            cssOverride={{ top: "19px", left: "19px" }}
          />
        )}
        {!loading && checked && <AnimatedCheckIcon />}
      </Box>
      <Switch
        checked={checked}
        disabled={loading || checked}
        onChange={handleSwitch}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Replace{" "}
            <strong>
              {usual_order?.line_items?.map((item) => item.note).join(", ")}
            </strong>{" "}
            with{" "}
            <strong>
              {order.line_items?.map((item) => item.note).join(", ")}
            </strong>{" "}
            as your usual?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSaveOrder}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  ) : null;
};

export default SaveOrder;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  padding: theme.spacing(1),
}));

const Dialog = styled(OriginalDialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.spacing(1.25),
  },
}));

const Switch = styled((props: SwitchProps) => (
  <OriginalSwitch disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: alpha(theme.palette.common.black, 0.4),
    opacity: 1,
  },
}));

const checkVariants = {
  hidden: { pathLength: 0 },
  visible: {
    pathLength: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
  exit: { pathLength: 0 },
};

const AnimatedCheckIcon = () => (
  <AnimatePresence>
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="18 22 30 30"
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.polyline
        points="20 40 28 48 44 24"
        fill="none"
        stroke="#096661"
        strokeWidth="4"
        variants={checkVariants}
      />
    </motion.svg>
  </AnimatePresence>
);
