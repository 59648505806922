import React, { useState, ComponentProps, MouseEvent, Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { styled, alpha } from "@mui/system";
import {
  AppBar as OriginalAppBar,
  Toolbar as OriginalToolbar,
  Box,
  Avatar as OriginalAvatar,
  Paper,
  Typography,
} from "@mui/material";
import { Button } from "../styled";
import AccountMenu from "./AccountMenu";
import { useSubdomain } from "../../contexts/SubdomainContext";

const useLogoPath = () => {
  const { subdomain } = useSubdomain();

  const logoPath = `${process.env.REACT_APP_CLOUDFRONT_URL}/${subdomain}`;

  return logoPath;
};

const Header = () => {
  const { isLoading, isAuthenticated, user, loginWithRedirect } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleAvatarClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box display="flex" alignItems="center">
          <img src={useLogoPath()} alt="logo" height="80px" />
        </Box>
        {!isLoading && !isAuthenticated && (
          <Button variant="contained" onClick={() => loginWithRedirect()}>
            Log in
          </Button>
        )}
        {isAuthenticated && (
          <Fragment>
            <AccountButton onClick={handleAvatarClick}>
              <Avatar />
            </AccountButton>
            <AccountMenu
              user={user}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          </Fragment>
        )}
      </Toolbar>
      {process.env.REACT_APP_DEPLOY_ENV === "dev" && (
        <Banner top={10} right={-60}>
          <Typography variant="h6">Dev</Typography>
        </Banner>
      )}
      {process.env.REACT_APP_DEPLOY_ENV === "demo" && (
        <Banner top={15} right={-65}>
          <Typography variant="h6">Demo</Typography>
        </Banner>
      )}
    </AppBar>
  );
};

export default Header;

const AppBar = styled(OriginalAppBar)({
  backgroundColor: "transparent",
  boxShadow: "none",
});

const Toolbar = styled(OriginalToolbar)({
  justifyContent: "space-between",
});

const AccountButton = styled(Button)(({ theme }) => ({
  width: "fit-content",
  animation: "fade-in 0.2s",
  "&:hover": {
    backgroundColor: "transparent",
    "& .MuiAvatar-root": {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
  },
}));

const Avatar = styled(OriginalAvatar)(({ theme }) => ({
  width: "35px",
  height: "35px",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
}));

interface BannerProps extends ComponentProps<typeof Paper> {
  top: number;
  right: number;
}

const Banner = styled(({ top, right, ...props }: BannerProps) => (
  <Paper {...props} />
))(({ theme, top, right }) => ({
  position: "fixed",
  top: top,
  right: right,
  transform: "rotate(45deg)",
  padding: `0 ${theme.spacing(8)}`,
  textAlign: "center",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
}));
