import React from "react";
import { Typography, Box, Link } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { PaymentResponse } from "../../types";

interface OrderConfirmationProps {
  paymentResponse: PaymentResponse;
}

const OrderConfirmation = ({ paymentResponse }: OrderConfirmationProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      margin={2}
      padding={2}
    >
      <CheckCircleOutline fontSize="large" color="success" />
      <Typography variant="h6" align="center" gutterBottom>
        Order Received
      </Typography>
      <Typography variant="body1" align="center">
        Your order has been successfully placed. Here is your{" "}
        <Link
          href={paymentResponse.payment.receipt_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          receipt
        </Link>
        . When you come in, please show it to your barista. Thank you for
        choosing our coffee shop!
      </Typography>
    </Box>
  );
};

export default OrderConfirmation;
