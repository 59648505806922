import React, { Dispatch, SetStateAction, useState } from "react";
import axios from "axios";
import { styled, useTheme } from "@mui/system";
import { Box, Theme } from "@mui/material";
import { Button as OriginalButton } from "../styled";
import {
  CreditCard as CreditCardIcon,
  Wallet as WalletIcon,
} from "@mui/icons-material";
import {
  PaymentForm,
  CreditCard,
  ApplePay,
  GooglePay,
} from "react-square-web-payments-sdk";
import { useSnackbar } from "notistack";
import { useSubdomain } from "../../contexts/SubdomainContext";
import { handleSquareError } from "../../utils/errorHandler";
import { Order, OrderResponse, PaymentResponse } from "../../types/index";

enum PaymentMethod {
  CARD = "card",
  WALLET = "wallet",
}

interface PaymentWrapperProps {
  order: Order;
  squareApplicationId: string;
  customerId: string | undefined;
  handleNext: () => void;
  orderResponse: OrderResponse | undefined;
  setOrderResponse: Dispatch<SetStateAction<OrderResponse | undefined>>;
  setPaymentResponse: Dispatch<SetStateAction<PaymentResponse | undefined>>;
  conversationId: string;
}

const PaymentWrapper = ({
  order,
  squareApplicationId,
  customerId,
  handleNext,
  orderResponse,
  setOrderResponse,
  setPaymentResponse,
  conversationId,
}: PaymentWrapperProps) => {
  const theme: Theme = useTheme();
  const locationId = order.location_id;
  const { subdomain } = useSubdomain();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.CARD);
  const orderAmount = ((order.total_money?.amount || 0) / 100).toString();

  const createOrder = async (order: Order) => {
    order.customer_id = customerId;
    if (!orderResponse) {
      return axios
        .post(
          `${process.env.REACT_APP_API_URL}/orders`,
          {
            order: order,
            conversation_id: conversationId,
          },
          {
            headers: {
              "Client-Subdomain": subdomain,
            },
          }
        )
        .then((response) => {
          setOrderResponse(response.data);
          return response.data;
        });
    } else {
      return Promise.resolve(orderResponse);
    }
  };

  const createPayment = async (
    sourceId: string,
    locationId: string,
    orderId: string,
    amountMoney: number
  ) => {
    const payment = {
      source_id: sourceId,
      location_id: locationId,
      customer_id: customerId,
      order_id: orderId,
      amount_money: amountMoney,
    };
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/payments`,
        {
          payment: payment,
          conversation_id: conversationId,
        },
        {
          headers: {
            "Client-Subdomain": subdomain,
          },
        }
      )
      .then((response) => {
        setPaymentResponse(response.data);
        return response.data;
      });
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <ButtonBox>
        <Button
          variant={paymentMethod === PaymentMethod.CARD ? "contained" : "text"}
          startIcon={<CreditCardIcon />}
          onClick={() => setPaymentMethod(PaymentMethod.CARD)}
        >
          Card
        </Button>
        <Button
          variant={
            paymentMethod === PaymentMethod.WALLET ? "contained" : "text"
          }
          startIcon={<WalletIcon />}
          onClick={() => setPaymentMethod(PaymentMethod.WALLET)}
        >
          Wallet
        </Button>
      </ButtonBox>
      <PaymentForm
        applicationId={squareApplicationId}
        locationId={locationId as string}
        cardTokenizeResponseReceived={async (token) => {
          try {
            const orderData = await createOrder(order);
            await createPayment(
              token.token || "",
              orderData.order.location_id,
              orderData.order.id,
              orderData.order.total_money
            );
            handleNext();
          } catch (error) {
            handleSquareError(error, enqueueSnackbar);
          }
        }}
        createPaymentRequest={() => ({
          countryCode: "US",
          currencyCode: "USD",
          total: {
            amount: orderAmount,
            label: "Total",
          },
        })}
      >
        {paymentMethod === PaymentMethod.CARD && (
          <CreditCard
            buttonProps={{
              css: {
                backgroundColor: theme.palette.primary.main,
                fontFamily: theme.typography.body1.fontFamily,
                "&:active": {
                  backgroundColor: theme.palette.primary.main,
                  transform: "scale(0.99)",
                },
              },
            }}
          />
        )}
        {paymentMethod === PaymentMethod.WALLET && (
          <Box display="flex" flexDirection="column" gap={1.25}>
            <ApplePay />
            <GooglePay buttonColor="white" />
          </Box>
        )}
      </PaymentForm>
    </Box>
  );
};

export default PaymentWrapper;

const Button = styled(OriginalButton)(({ theme, variant }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  ...(variant === "text" && {
    boxShadow: "0px 1px 4px lightgrey",
  }),
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(5),
  padding: theme.spacing(1.25),
  marginBottom: theme.spacing(1.25),
}));
