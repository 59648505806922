import React, { Dispatch, SetStateAction, useState } from "react";
import { styled, useTheme } from "@mui/system";
import {
  Box,
  Step,
  Stepper as OriginalStepper,
  StepLabel,
  StepIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PaymentWrapper from "./PaymentForm";
import LocationForm from "./LocationForm";
import ContactForm from "./ContactForm";
import OrderConfirmation from "./OrderConfirmation";
import { useSnackbar } from "notistack";
import { useSubdomain } from "../../contexts/SubdomainContext";
import { handleSquareError } from "../../utils/errorHandler";
import { postCustomer, patchUser } from "../../api";
import {
  User,
  Location,
  Order,
  OrderResponse,
  PaymentResponse,
  CustomerRequest,
} from "../../types";

interface OrderCompletionFlowProps {
  user: User | undefined;
  locations: Location[];
  selectedLocation: Location | undefined;
  setSelectedLocation: Dispatch<SetStateAction<Location | undefined>>;
  order: Order;
  conversationId: string;
}

const OrderCompletionFlow = ({
  user,
  locations,
  selectedLocation,
  setSelectedLocation,
  order,
  conversationId,
}: OrderCompletionFlowProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const { subdomain } = useSubdomain();
  const squareApplicationId = process.env.REACT_APP_SQUARE_APPLICATION_ID || "";

  const [activeStep, setActiveStep] = useState(0);
  const [customerId, setCustomerId] = useState(
    user?.square_customer_id_by_client?.[subdomain || ""]
  );
  const [orderResponse, setOrderResponse] = useState<OrderResponse>();
  const [paymentResponse, setPaymentResponse] = useState<PaymentResponse>();
  const [loading, setLoading] = useState(false);

  const isUserComplete = user && user.first_name && user.last_name;
  const locationStep =
    activeStep > 0 ? selectedLocation?.name : "Pickup location";
  const steps = isUserComplete
    ? [locationStep, "Payment"]
    : [locationStep, "Contact information", "Payment"];

  const createCustomer = async (customerRequest: CustomerRequest) => {
    try {
      setLoading(true);
      const { data: customerResponse } = await postCustomer(
        customerRequest,
        subdomain || ""
      );
      return customerResponse;
    } catch (error) {
      handleSquareError(error, enqueueSnackbar);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (userId: string, userData: any) => {
    patchUser(userId, userData).catch((error) =>
      console.error("Error updating user", error)
    );
  };

  const handleCustomerCreation = async (
    customerRequest: CustomerRequest,
    proceed: () => void,
    userData?: any
  ) => {
    try {
      setLoading(true);
      const customerResponse = await createCustomer(customerRequest);
      if (customerResponse) {
        setCustomerId(customerResponse.customer.id);
        await updateUser(user?._id || "", {
          square_customer_id_by_client: {
            ...(user?.square_customer_id_by_client || {}),
            [subdomain || ""]: customerResponse.customer.id,
          },
          ...userData,
        });
        proceed();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleNext = async () => {
    if (activeStep === 0 && isUserComplete && !customerId) {
      const customerRequest = {
        customer: {
          given_name: user?.first_name || "",
          family_name: user?.last_name || "",
          email_address: user?.email || "",
        },
      };
      await handleCustomerCreation(customerRequest, () =>
        setActiveStep((prevStep) => prevStep + 1)
      );
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  return (
    <Box>
      <Typography variant="h6" textAlign="center" gutterBottom>
        Complete order
      </Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon}>
              {steps.length < 3
                ? label
                : (!isMobile || index === activeStep) && label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <LocationForm
          locations={locations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          handleNext={handleNext}
          loading={loading}
        />
      )}
      {!isUserComplete && activeStep === 1 && (
        <ContactForm
          user={user}
          handleBack={handleBack}
          handleNext={handleNext}
          handleCustomerCreation={handleCustomerCreation}
          loading={loading}
        />
      )}
      {(isUserComplete ? activeStep === 1 : activeStep === 2) && (
        <PaymentWrapper
          order={order}
          squareApplicationId={squareApplicationId}
          customerId={customerId}
          handleNext={handleNext}
          orderResponse={orderResponse}
          setOrderResponse={setOrderResponse}
          setPaymentResponse={setPaymentResponse}
          conversationId={conversationId}
        />
      )}
      {(isUserComplete ? activeStep === 2 : activeStep === 3) &&
        paymentResponse && (
          <OrderConfirmation paymentResponse={paymentResponse} />
        )}
    </Box>
  );
};

export default OrderCompletionFlow;

export const Stepper = styled(OriginalStepper)(({ theme }) => ({
  marginBottom: theme.spacing(1.25),
}));
