import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Chip as OriginalChip,
  Box as OriginalBox,
  IconButton as OriginalIconButton,
} from "@mui/material";
import {
  Assistant as RecommendationsIcon,
  AssistantOutlined as OutlinedRecommendationsIcon,
} from "@mui/icons-material";
import { getRecommendedQuestions } from "../../api";
import { Message } from "../../types";

interface RecommendedQuestionsProps {
  messages: Message[];
  handleSendMessage: (message: string) => void;
  loading: boolean;
  displayMessageStream: boolean;
}

const RecommendedQuestions = ({
  messages,
  handleSendMessage,
  loading,
  displayMessageStream,
}: RecommendedQuestionsProps) => {
  const [recommendedQuestions, setRecommendedQuestions] = useState([]);
  const [showQuestions, setShowQuestions] = useState(true);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.choices[0].message.role === "assistant") {
      getRecommendedQuestions(lastMessage)
        .then((response) => setRecommendedQuestions(response.data))
        .catch((error) => console.error(error));
    }
  }, [messages]);

  useEffect(() => {
    if (loading || displayMessageStream) {
      if (recommendedQuestions.length > 0) {
        setRecommendedQuestions([]);
        setShowQuestions(false);
      }
    }
  }, [loading, displayMessageStream, recommendedQuestions]);

  return recommendedQuestions.length > 0 ? (
    showQuestions ? (
      <Box>
        <QuestionsBox>
          {recommendedQuestions.map((question, index) => (
            <Chip
              key={index}
              label={question}
              onClick={() => handleSendMessage(question)}
              color="primary"
              variant="outlined"
            />
          ))}
        </QuestionsBox>
        <IconButton
          disableRipple
          onClick={() => setShowQuestions(!showQuestions)}
        >
          <OutlinedRecommendationsIcon color="primary" />
        </IconButton>
      </Box>
    ) : (
      <RecommendationsIconButton
        disableRipple
        onClick={() => setShowQuestions(!showQuestions)}
      >
        <RecommendationsIcon color="primary" />
      </RecommendationsIconButton>
    )
  ) : null;
};

export default RecommendedQuestions;

const Box = styled(OriginalBox)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(1),
  height: theme.spacing(6),
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    height: theme.spacing(5),
  },
  [theme.breakpoints.up("md")]: {
    flexWrap: "wrap",
    justifyContent: "center",
  },
}));

const QuestionsBox = styled(OriginalBox)(({ theme }) => ({
  display: "flex",
  overflow: "auto",
  gap: theme.spacing(1),
  padding: theme.spacing(0.5),
}));

const Chip = styled(OriginalChip)(({ theme }) => ({
  fontSize: "12px",
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1.25),
  boxShadow: `0px 0px 4px lightgrey`,
  "&:focus": {
    boxShadow: `0px 0px 4px lightgrey`,
  },
  border: "none",
  animation: "fade-in 0.2s",
  whiteSpace: "nowrap",
  minWidth: "max-content",
  "& .MuiTouchRipple-root": {
    display: "none",
  },
}));

const IconButton = styled(OriginalIconButton)({
  padding: 0,
  animation: "fade-in 0.2s",
});

const RecommendationsIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 15,
  bottom: 110,

  [theme.breakpoints.up("sm")]: {
    right: "50%",
    transform: "translateX(50%)",
  },
}));
