import { styled } from "@mui/system";
import {
  Button as OriginalButton,
  CircularProgress as OriginalCircularProgress,
} from "@mui/material";

export const Button = styled(OriginalButton)(({ theme }) => ({
  textTransform: "none",
  borderRadius: theme.spacing(1.25),
  "& .MuiTouchRipple-root": {
    display: "none",
  },
}));

export const CircularProgress = styled(OriginalCircularProgress)(
  ({ theme }) => ({
    color: theme.palette.common.white,
  })
);
